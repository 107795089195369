import _helper from "../../helper";
import _ActionSet from "../../ActionSet";
import _types from "../../types";
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.History = exports.replace = exports.push = exports.Action = void 0;
var helper_1 = _helper;
var ActionSet_1 = _ActionSet;
var types_1 = _types;
var Action;
(function (Action) {
  Action["PUSH"] = "APP::NAVIGATION::HISTORY::PUSH";
  Action["REPLACE"] = "APP::NAVIGATION::HISTORY::REPLACE";
})(Action = exports.Action || (exports.Action = {}));
function push(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.Navigation,
    type: Action.PUSH
  });
}
exports.push = push;
function replace(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.Navigation,
    type: Action.REPLACE
  });
}
exports.replace = replace;
var History = /** @class */function (_super) {
  __extends(History, _super);
  function History(app) {
    return _super.call(this, app, "History", types_1.Group.Navigation) || this;
  }
  Object.defineProperty(History.prototype, "payload", {
    get: function () {
      return {
        id: this.id
      };
    },
    enumerable: false,
    configurable: true
  });
  History.prototype.dispatch = function (type, path) {
    var payload = __assign(__assign({}, this.payload), {
      path: path
    });
    switch (type) {
      case Action.PUSH:
        this.app.dispatch(push(payload));
        break;
      case Action.REPLACE:
        this.app.dispatch(replace(payload));
        break;
    }
    return this;
  };
  return History;
}(ActionSet_1.ActionSet);
exports.History = History;
export default exports;
export const __esModule = exports.__esModule;
const _History = exports.History,
  _replace = exports.replace,
  _push = exports.push,
  _Action = exports.Action;
export { _History as History, _replace as replace, _push as push, _Action as Action };